/* ----- Style ----- */
import '../stylesheets/tarteaucitron'

/* ----- TAC ----- */
const { tarteaucitron } = require('exports-loader?exports=tarteaucitron!tarteaucitronjs/tarteaucitron')
global.tarteaucitron = tarteaucitron

/* ----- Variables ----- */
const privacyPolicyUrl = 'politique-de-confidentialite'

/* ----- Functions ----- */
tarteaucitron.engage = function (id, translation_key) {
  'use strict'

  if (translation_key === null || translation_key === undefined || !tarteaucitron.lang.hasOwnProperty(translation_key)) {
    translation_key = 'fallback'
  }

  var html = '',
    r = Math.floor(Math.random() * 100000),
    engage = tarteaucitron.services[id].name + ' ' + tarteaucitron.lang[translation_key]

  if (tarteaucitron.lang['engage-' + id] !== undefined) {
    engage = tarteaucitron.lang['engage-' + id]
  }

  html += '<div class="tac_activate tac_activate_' + id + ' tac_activate--' + translation_key + '">'
  html += '   <div class="tac_float">'
  html += '      ' + engage
  html += '      <button type="button" class="tarteaucitronAllow" id="Eng' + r + 'ed' + id + '">'
  html += '          <span class="tarteaucitronCheck"></span> ' + tarteaucitron.lang.allow
  html += '       </button>'
  html += '   </div>'
  html += '</div>'

  return html
}

tarteaucitron.fixSelfXSS = function(html) { // Opsone fix for strict mode
  return html.toString().replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;').replace(/'/g, '&#039;')
}

tarteaucitron.getLanguage = function() {
  return document.documentElement.lang.substr(0, 2)
}

/* ----- Locales ----- */
switch (tarteaucitron.getLanguage()) {
  case 'fr':
    require('tarteaucitronjs/lang/tarteaucitron.fr.js')
    break
  default:
    require('tarteaucitronjs/lang/tarteaucitron.fr.js')
}

const customText = {
  "fr": {
    "alertBigPrivacy": `Nous utilisons des cookies pour améliorer votre expérience utilisateur.<br> Vos préférences sont modifiables à tout moment en cliquant sur <span class="is-nowrap">« Cookies »</span> en bas à droite de nos pages.<span class="privacy-policy-block"><br> Pour en savoir plus, consultez notre <a href="${privacyPolicyUrl}" target="_blank">Politique de confidentialité</a>.</span>`,
    "alertSmall": "Cookies",
    "middleBarHead": "Gérer mes cookies",
    "disclaimer": `En autorisant ces services tiers, vous acceptez le dépôt et la lecture de cookies et l'utilisation de technologies de suivi nécessaires à leur bon fonctionnement.<br> Vos préférences sont modifiables à tout moment en cliquant sur <span class="is-nowrap">« Cookies »</span> en bas à droite de nos pages.<br> Pour en savoir plus, consultez notre <a href="${privacyPolicyUrl}" target="_blank">Politique de confidentialité</a>.`,
    "fallback_required": "est désactivé.<br /> L'autorisation de ce service est obligatoire pour utiliser ce formulaire.",
  },
}

let tarteaucitronCustomText = tarteaucitron.getLanguage() in customText ? customText[tarteaucitron.getLanguage()] : ''
tarteaucitron.lang = { ...tarteaucitron.lang, ...tarteaucitronCustomText }

/* ----- Services ----- */
require('tarteaucitronjs/tarteaucitron.services.js')


/* ----- Initialization ----- */
tarteaucitron.init({
  privacyUrl: privacyPolicyUrl, /* Privacy policy url */
  hashtag: '#tarteaucitron', /* Open the panel with this hashtag */
  cookieName: 'tarteaucitron', /* Cookie name */
  orientation: 'middle', /* Banner position (top - bottom - middle - popup) */
  groupServices: false, /* Group services by category */
  showAlertSmall: true, /* Show the small banner on bottom right */
  cookieslist: false, /* Show the cookie list */
  closePopup: false, /* Show a close X on the banner */
  showIcon: false, /* Show cookie icon to manage cookies */
  //iconSrc: '', /* Optionnal: URL or base64 encoded image */
  iconPosition: 'BottomRight', /* BottomRight, BottomLeft, TopRight and TopLeft */
  adblocker: false, /* Show a Warning if an adblocker is detected */
  denyAllCta: true, /* Show the deny all button */
  acceptAllCta: true, /* Show the accept all button when highPrivacy on */
  highPrivacy: true, /* HIGHLY RECOMMANDED Disable auto consent */
  handleBrowserDNTRequest: false, /* If Do Not Track == 1, disallow all */
  removeCredit: true, /* Remove credit link */
  moreInfoLink: true, /* Show more info link */
  useExternalCss: true, /* If false, the tarteaucitron.css file will be loaded */
  useExternalJs: true, /* If false, the tarteaucitron.js file will be loaded */
  //cookieDomain: '.my-multisite-domaine.fr', /* Shared cookie for multisite */
  readmoreLink: '', /* Change the default readmore link */
  mandatory: true, /* Show a message about mandatory cookies */
})

document.addEventListener('turbolinks:render', (e) => {
  tarteaucitron.added = {}
  tarteaucitron.initEvents.loadEvent(false)
  tarteaucitron.job.forEach((e) => { tarteaucitron.job.push(e) })
})

document.addEventListener('turbolinks:load', (e) => {
  if (window.ga) {
    window.ga('send', 'pageview', window.location.pathname);
  }
})

/* ----- Add services to TAC ----- */
tarteaucitron.user.analyticsUa = 'UA-140070265-1'
tarteaucitron.user.analyticsAnonymizeIp = true;

tarteaucitron.job = []
tarteaucitron.job.push('hcaptcha')


  tarteaucitron.job.push('analytics')

